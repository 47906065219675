import { IAccountStateModel } from "./auth.state.models";

const ACTION_SCOPE = '[Auth]';

export namespace AuthActions {
  export class GetAuthToken {
    static readonly type = `${ACTION_SCOPE} Get Token`;
    constructor(public token: string) { }
  }

  export class GetActiveAccountDetails {
    static readonly type = `${ACTION_SCOPE} Get Account Details`;
    constructor(public accountId?: string) { }
  }

  export class SetAuthToken {
    static readonly type = `${ACTION_SCOPE} Set Token`;
    constructor(public token: string) { }
  }

  export class Login {
    static readonly type = `${ACTION_SCOPE} Login`;
    constructor(public payload: { email: string; password: string }) { }
  }

  export class SetLoadingStatus {
    static readonly type = `${ACTION_SCOPE} Set Loading`;
    constructor(public payload: boolean) { }
  }

  export class GetAccountSetupStatus {
    static readonly type = `${ACTION_SCOPE} Get Account Setup Status`;
    constructor(public payload: boolean) { }
  }
  export class Signup {
    static readonly type = `${ACTION_SCOPE} Signup`;
    constructor(public payload: { userId: string; token: string }) { }
  }

  export class SetAccountID {
    static readonly type = `${ACTION_SCOPE}  Set Account ID`;
    constructor(public account_id: string) { }
  }
  export class SetPortFolioBalance {
    static readonly type = `${ACTION_SCOPE}  Set Portfolio Balance`;
    constructor(public account_id: string, public currency:string) { }
  }
    export class SetWalletBalance {
    static readonly type = `${ACTION_SCOPE}  Set Wallet Balance`;
    constructor(public account_id: string) { }
  }

  export class LoadUserAccounts {
    static readonly type = `${ACTION_SCOPE} Load User Accounts`;
  }

  export class SwitchAccount {
    static readonly type = `${ACTION_SCOPE} Switch Active Accounts`;
    constructor(public accountId: string) { }
  }
  export class EnumsList {
    static readonly type = `${ACTION_SCOPE} Enums List`;
    constructor() { }
  }

  export class LoadUserAccountsSuccess {
    static readonly type = `${ACTION_SCOPE} Load User Accounts Success`;
    constructor(
      public activeAccount: IAccountStateModel,
      public allAccounts: IAccountStateModel[]
    ) { }
  }

  export class LoadUserAccountsFailure {
    static readonly type = `${ACTION_SCOPE} Load User Accounts Failure`;
    constructor(public error: any) { }
  }
}
